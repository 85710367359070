import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import AppLoader from 'components/ui-kit/AppLoader/index'
import { LOADER_TYPE, ROUTE_PATHS } from 'constants/index'
import { colors } from 'theme/colors/index'
import CustomRoutes from './routes'
import { Dialog, DialogTitle, Typography, DialogContent, DialogContentText } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CustomButton from 'components/ui-kit/Button/index'
import { useDispatch, useSelector } from 'react-redux'
import { handelLogoutDialog } from 'redux-thunk/redux/user/user.slice'
import { removeAuthToken } from 'helpers/cookie.helpers'
// import { NavLink, useLocation, useNavigate } from 'react-router-dom'

const Routes = ({ pageLoader }) => {
  const { logoutDialog } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  // const navigate  = useNavigate()
  const handelDialogClick = ({ doLogout }) => {
    dispatch(handelLogoutDialog(false))

    if (doLogout) {
      removeAuthToken()
    // navigate(ROUTE_PATHS.home, { replace: true })
    }
  }
  return (
    <Router>
      <Suspense fallback={
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%'
        }}
        >
          {!pageLoader && <AppLoader variant={LOADER_TYPE.SCALE} color={colors.white} />}
        </div>
      }
      >
        <CustomRoutes />
      </Suspense>
    </Router>
  )
}

export default Routes
