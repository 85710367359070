const config = {
  apiGateways: {
    BASE_URL_1: `${process.env.REACT_APP_BASE_URL}`,

    STREAMING_BASE_URL_1: 'https://stream-api.onlybets.tv/'
  },
  SOCKET_URL: `${process.env.REACT_APP_WS_URL}`,
  GOOGLE_API_KEY: `${process.env.REACT_APP_GOOGLE_API_KEY || '6LeQFqwpAAAAAJorlbsfJDoHsSHQDxtTUkS1Www3'}`,
  BASE_URL: `${process.env.REACT_APP_MAIN_BASEURL}`,

  BETBY_BRAND_ID: `${process.env.REACT_APP_BETBY_BRAND_ID || '2382631561670561792'}`,
  BETBY_SCRIPT_URL: `${process.env.REACT_APP_BETBY_SCRIPT_URL || 'https://ui.invisiblesport.com/bt-renderer.min.js'}`,
  BETBY_THEME: `${process.env.REACT_APP_BETBY_THEME || 'default'}`,
  BETBY_ENABLED: process.env.REACT_APP_BETBY_ENABLED === '1' ? true : false,

  STREAMING_PLAYER: `${process.env.REACT_APP_STREAMING_PLAYER}`,
  STREAMING_SOCKET_URL: 'https://stream-api.onlybets.tv/',
  ENV: `${process.env.REACT_APP_ENV}`
}

export default config
