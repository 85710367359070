import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Typography,
  Divider
} from '@mui/material';
import { useReferAFriendStyles } from './style';
import { v4 as uuidv4 } from 'uuid';
import CopyFiledComponent from 'components/CopyField/CopyFiledComponent';
import config from 'config/app.config';
import { useSelector, useDispatch } from 'react-redux';
import { ROUTE_PATHS } from 'constants/index';
import { colors } from 'theme/colors/index';
import { fetchBannerThunk } from "redux-thunk/thunk/casino/casino.thunk";

const ReferAFriend = () => {
  const classes = useReferAFriendStyles();
  const { t } = useTranslation();
  const { userInformationData } = useSelector((state) => state.user)
  const baseUrl = config?.BASE_URL
  const { bannerListData } = useSelector((state) => state.casino);
  const dispatch = useDispatch();

  const [isTextCopied, setIsTextCopied] = useState({
    isCopy: false,
    message: ''
  })

  const generateRefferalUrl = () => {
    const bonusCode = bannerListData?.availableJoiningBonus?.bonusCode;
    const query = {
      reffCode: userInformationData?.uniqueId,
      ...(bonusCode ? {bonusCode}: {})
    };
    return `${baseUrl}${ROUTE_PATHS?.register}${bonusCode ? `?${new URLSearchParams(query).toString()}` : ''}`
  }

  const handleCopyClick = (copyData) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(copyData)
      const message = t('copiedMessage', { text: t('refferalCode') })
      setIsTextCopied({ isCopy: true, message })
    }
    setTimeout(() => {
      setIsTextCopied({ isCopy: false, message: '' })
    }, 10000)
  }


  const showData = [{
    title: t('earnuptoBRLOnPerInvitation'),
    color: colors.rejectedRed,
    variant: 'h6',
    class: "enter-text"
  },
  {
    title: t('inviteFriendandEarnMoney'),
    color: colors.white,
    variant: 'h6',
    class: classes?.centeredTypography
  },
  {
    title: t('receiveCaseBalanceForEveryFriendYourRefer'),
    color: colors.white,
    variant: 'h6',
    class: "receive-case-balance"
  }
  // {
  //   title: t('howInvitationWork'),
  //   color: colors.white,
  //   variant: 'h6',
  //   class: "how-it-works"
  // }
  ]

  useEffect(() => {
    if (!bannerListData) dispatch(fetchBannerThunk({}));
  }, []);

  return (
    <>

      <Grid container sx={{ justifyContent: 'center' }}>
        <Box className={classes.refferFriendContent} >
          {showData?.map(data => {
            return (
              <Typography key={uuidv4()} variant={data?.variant} className={data?.class} sx={{ color: data?.color }}>{data?.title}</Typography>
            )
          })}
          <Divider className={classes.divider} />
          <Typography key={uuidv4()} variant="h5" sx={{ color: colors.white, margin: "15px 0" }}>{t('shareInviteCode')}</Typography>
        </Box>
      </Grid>

      <Grid p={2} pt={0}>
        <CopyFiledComponent
          value={generateRefferalUrl()}
          // title={t(`yourRefferalCode`)}
          handleCopyClick={() => handleCopyClick(generateRefferalUrl())}
          name='referAFriend'
          copyTitleMessage={t('copyTitleMessage', { title: t('yourRefferalCode') })}
          setIsTextCopied={setIsTextCopied}
          isTextCopied={isTextCopied}
        />
      </Grid>

    </>
  );
};

ReferAFriend.defaultProps = {
  handleOnChange: () => { },
  onButtonClick: () => { },
  title: "Button Title",
};

export default memo(ReferAFriend);
