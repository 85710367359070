import { combineReducers } from '@reduxjs/toolkit'

import loaderSlice from './loader/loader.slice'
import toasterSlice from './toaster/toaster.slice'
import userSlice from './user/user.slice'
import casinoSlice from './casino/casino.slice'
import cmsSlice from './cms/cms.slice'
import commonSlice from './common/common.slice'
import footerSlice from './footer/footer.slice'
import loyaltySlice from './loyalty/loyalty.slice'
import winnersSlice from './winners/winners.slice'
import transactionSlice from './Transaction/transaction.slice'

import paymentSlice from './payment/payment.slice'
import streamingSlice from './streaming/streaming.slice'

import influendersSlice from './influencer/influencer.slice';

export const rootReducer = combineReducers({
  user: userSlice,
  toaster: toasterSlice,
  loader: loaderSlice,
  casino: casinoSlice,
  cms: cmsSlice,
  common: commonSlice,
  footer: footerSlice,
  loyalty: loyaltySlice,
  winners: winnersSlice,
  transaction: transactionSlice,

  payment: paymentSlice,
  streaming: streamingSlice,

  influencers: influendersSlice,
})
