import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  displayHeader: {
    display: "none",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // padding: theme.spacing(0.4, 1),
    minHeight: theme.spacing(3.6),
    borderWidth: "0 0 4px 0",
    borderStyle: "solid",
    borderColor: theme.border.darkBorder,
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: 999,
    // maxWidth: "1920px",
    margin: "0 auto",
    background: theme.colors.primaryLightBlue,

    [theme.breakpoints.down("lg")]: {
      maxWidth: '100vw',
    },
  },
  headerLeft: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  brandLogo: {
    "& a": {
      display: "flex",
      alignItems: "center",
    },
  },
  sidebarCollapseIcon: {
    "& a": {
      textAlign: "right",
      display: "block",
      padding: theme.spacing(0, 1),
    },
  },
  headerLinksWrapper: {
    width: '100%',
    overflow: 'auto'
  },
  menuWrap: {
    display: 'flex',
    width: '100%',
    marginLeft: theme.spacing(2),
    "& a": {
      textAlign: 'center',
      color: theme.colors.textColor,
      textDecoration: "none",
      padding: theme.spacing(1.19, 1),
      fontWeight: theme.typography.fontWeightBold,
      position: "relative",
      fontSize: theme.spacing(0.875),
      [theme.breakpoints.down("xl")]: {
        padding: theme.spacing(1.19, 0.5),
        fontSize: theme.spacing(0.75),
      },
      "&:after": {
        content: ' "" ',
        transition: 'background ease .5s',
        backgroundColor: 'transparent',
      },
      "&:hover": {
        color: theme.colors.white,
        "&:after": {
          content: ' "" ',
          backgroundColor: theme.colors.btnSecondary,
          width: "100%",
          height: "3px",
          bottom: "0",
          left: "0",
          position: "absolute",
        },
      },
      "&.active": {
        color: theme.colors.white,
        "&:after": {
          content: ' "" ',
          backgroundColor: theme.colors.btnSecondary,
          width: "100%",
          height: "3px",
          bottom: "0",
          left: "0",
          position: "absolute",
        },
      },
    },
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    "& .balanceSection": {
      margin: theme.spacing(0, 0.62),
      display: "flex",
      "& span": {
        color: theme.colors.UserSectionColor,
        fontSize: theme.spacing(0.75),
        fontStyle: "normal",
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: "normal",
        "&:nth-child(2)": {
          color: theme.colors.white,
          fontSize: theme.spacing(0.75),
          fontStyle: "normal",
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: "normal",
        },
      },
    },
    "& .userIcon": {
      cursor: "pointer",
      padding: "0px 5px",
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    "& .userDetail": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& p": {
        color: theme.colors.white,
        fontSize: theme.spacing(0.75),
        fontStyle: "normal",
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: "normal",
        "&:nth-child(2)": {
          color: theme.colors.UserSectionColor,
          fontSize: theme.spacing(0.625),
          fontStyle: "normal",
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: "normal",
        },
      },
    },
    "& .MuiButton-root": {
      padding: theme.spacing(0, 0.625),
      // minWidth: theme.spacing(7.5),
    },
    "& .MuiInputBase-root": {
      '& .MuiInputBase-input': {
        minWidth: theme.spacing(4.9),
      }
    },
    "& .MuiButton-text": {
      minWidth: 'auto',
      padding: theme.spacing(0, 0.9),
    },
  },

  btnPrimary: {
    background: theme.colors.btnPrimary,
    color: theme.colors.white,
    fontSize: theme.spacing(0.875),
    fontWeight: theme.typography.fontWeightSemiBold,
    marginRight: theme.spacing(0.625),
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(0.25),
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },

    "&:hover": {
      background: theme.colors.btnSecondary,
      color: theme.colors.white,
    },
  },
  btnSecondary: {
    background: theme.colors.btnSecondary,
    color: theme.colors.tableEvenBg,
    fontWeight: theme.typography.fontWeightSemiBold,
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(0.25),
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
    },
    "&:hover": {
      background: theme.colors.btnSecondaryHover,
      color: theme.colors.white,
    },
  },
  mobHeaderLeft: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexGrow: "1",
      alignItems: "center",
    },
  },
  mobMenuIcon: {
    marginRight: theme.spacing(0.625),
  },
  mobSidebar: {
    background: theme.colors.primaryLightBlue,
    position: "fixed",
    padding: theme.spacing(1, 0),
    top: theme.spacing(3.46),
    left: "-278px",
    width: "278px",
    zIndex: "1",
  },
  mobSidebarMenuList: {
    "& a": {
      display: "block",
      textDecoration: "none",
      color: theme.colors.white,
      padding: theme.spacing(1),
      fontSize: theme.spacing(0.875),
      fontWeight: theme.typography.fontWeightBold,
      "&:nth-child(1)": {
        borderBottom: `2px solid ${theme.colors.btnSecondary}`,
      },
    },
  },
  headerTopContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: '1600px',
    padding: theme.spacing(0, 2),
    margin: 'auto',
    "& .web-search": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.4, 1),
    },
  },
  subHeaderWrapp: {
    background: theme.colors.themeCard,
    width: "100%",
  },
  subHeader: {
    padding: theme.spacing(0.4, 2),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.spacing(0.75),
    maxWidth: '1600px',
    margin: '0 auto',
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.4, 1, 0),
      display: "none",
    },
    "& > .MuiTypography-body1": {
      fontSize: theme.spacing(0.875),
      textTransform: "uppercase",
    },
    "& .sub-header-right": {
      display: "flex",
      alignItems: "center",
      "& .MuiButton-root": {
        background: "transparent",
        boxShadow: "none",
        color: theme.colors.white,
        fontWeight: theme.typography.fontWeightRegular,
        padding: 0,
        minWidth: "auto",
        minHeight: "auto",
        margin: "0 0 0 8px",
        "& svg": {
          marginLeft: theme.spacing(0.625),
        },
      },
      "& .MuiTypography-body1": {
        fontSize: theme.spacing(0.75),
      },
    },
    "& .sub-header-web-date": {
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    "& .sub-header-mob-date": {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
      [theme.breakpoints.down("lg")]: {
        display: "block",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: theme.spacing(0.875),
    },
  },

  dropdownElementProfile: {
    "& .MuiPaper-root": {
      border: `1px solid ${theme.colors.primaryLightBlue}`,
      background: theme.colors.primaryDarkBlue,
      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.35)",
      [theme.breakpoints.down("md")]: {
        top: "58px !important",
      },
      [theme.breakpoints.down("sm")]: {
        top: "58px !important",
        width: "95% !important",
        minWidth: "95% !important",
        left: "0 !important",
        right: "0px !important",
        margin: "0px auto !important",
        boxShadow: "inherit",
        border: "none",
        borderRadius: "0",
      },
    },
    "& ul": {
      paddingTop: "0",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
        paddingTop: "0",
      },
      "& p": {
        background: theme.colors.primaryLightBlue,
        color: "rgba(255, 255, 255)",
        padding: "4px 15px",
        textAlign: "center",
        fontSize: "14px",
      },
      "& li": {
        color: theme.colors.white,
        fontFamily: "IBM Plex Sans, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
        [theme.breakpoints.down("sm")]: {
          backgroundColor: theme.colors.themeCard,
          borderBottom: `1px solid ${theme.colors.primaryDarkBlue}`,
          minHeight: "auto",
          lineHeight: "30px",
          fontSize: "12px",
        },
        "&.active": {
          backgroundColor: theme.colors.themeCard,
        },
      },
    },
  },

  depositBtn: {
    display: "none",
  },

  dropdownElementReport: {
    "& .MuiPaper-root": {
      border: `1px solid ${theme.colors.primaryLightBlue}`,
      background: theme.colors.primaryDarkBlue,
      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.35)",
    },
    "& ul": {
      "& li": {
        color: theme.colors.white,
        fontFamily: 'IBM Plex Sans", sans-serif',
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
        "&.active": {
          backgroundColor: theme.colors.primaryLightBlue,
        },
      },
    },
  },

  showMobSidebar: {
    left: "0px !important",
  },
  logoSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: "0 0 1px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& .MuiButton-root": {
      background: `${theme.colors.primaryDarkBlue}!important`,
      padding: `${theme.spacing(0, 0.5)}!important`,
      borderRadius: theme.spacing(0.5),
      display: "flex",
      justifyContent: "space-between",
      minWidth: "auto",
      "& span": {
        margin: theme.spacing(0, 0.5),
      },
    },
  },
  amountDropdown: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.primaryDarkBlue,
    padding: "5px 10px",
    borderRadius: "4px",
    maxHeight: theme.spacing(2.5),
    "& .custom-dropdown": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& button": {
        minWidth: "100px",
        minHeight: theme.spacing(1.875),
      },
    },
    "& .amountSection ": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    [theme.breakpoints.down("md")]: {
      // display: 'none',
      padding: "8px 10px",
      fontSize: theme.spacing(0.8),
      minHeight: '40px',
    },
  },
  userIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      width: "25px",
      height: "25px",
      borderRadius: "50%",
      // border: '2px solid #000'
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .MuiButton-root": {
      display: "flex",
      flexDirection: "column",
      background: "transparent",
      boxShadow: "none",
      minWidth: "unset",
      [theme.breakpoints.down("md")]: {
        padding: "0",
        display: "none",
      },
    },
    "&  .user-count": {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.colors.primaryDarkBlue,
      justifyContent: "center",
      gap: "8px",
      borderRadius: "10px",
      lineHeight: "normal",
      padding: "0 10px",
      color: theme.colors.white,
      fontSize: theme.spacing(0.875),
      "& svg": {
        height: theme.spacing(1),
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },

  mobDeposit: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginRight: "0.5rem",
    },
  },
  desktopDeposit: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  headerUsername: {
    "& p": {
      backgroundColor: `${theme.colors.btnSecondary} !important`,
      textAlign: "left !important",
      fontWeight: "600",
      marginTop: "0px",
    },
  },

  dropdownLanguageTitle: {
    marginLeft: "5px",
    color: theme.colors.white,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  languageIconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0.5),
    },
  },
}));
