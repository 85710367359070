import { EnglishIcon } from 'components/ui-kit/icons/iconComponents/EnglishIcon'
import { FrenchIcon } from 'components/ui-kit/icons/iconComponents/FrenchIcon'

/* ==========================================================================
  LANGUAGES
  ========================================================================== */
const LANGUAGES = [
  { label: 'English', languageCode: 'en', flag: EnglishIcon },
  // { label: 'French', languageCode: 'fr', flag: FrenchIcon },
  { label: 'Spanish', languageCode: 'es', flag: FrenchIcon },
  { label: 'Portuguese', languageCode: 'pt', flag: FrenchIcon }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
  Local Storage / Cookie data
========================================================================== */
const TOKEN = 'authtoken'
const ROLE = 'authrole'
const LANGUAGE = 'language'
const BETBY_TOKEN = 'betby_token'
const IS_FIRST_ACCESS = 'isFirstAccess'
const UNIQUE_ID = 'unique_id'
const VISIT_SESSION = 'visit_session'

/* ==========================================================================
  Types of Loader
========================================================================== */
const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  Loader Overlay Area
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader',
  dialog: 'dialogLoader'
}

/* ==========================================================================
  TOASTER / NOTIFICATION types
========================================================================== */
const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  All Single routes
========================================================================== */
const ROUTE_PATHS = {
  home: '/home',
  register: '/signup',
  login: '/login',
  casino: '/',
  casino2: '/casino',
  liveCasino: '/live-casino',
  winners: '/winners',
  forgotPassword: '/forget-password',
  resetPassword: '/reset-password',
  promotions: '/promotions',
  profile: '/profile',
  userbalance: '/user-balance',
  bonus: '/bonus',
  deposit: '/deposit',
  withdrawal: '/withdrawal',
  withdrawalRequest: '/withdrawal-request',
  transaction: '/transaction',
  bettingslip: '/bettingslip',
  betHistory: '/bet-history',
  affiliates: '/affiliates',
  viewall: '/view-all/:subCategoryId',
  casinoGame: '/casino/:gameId',
  influencers: '/influencers',
  verifyEmail: '/verify-email',
  loyalty: '/loyalty',
  playingNow: '/playing-now',
  kyc: '/kyc',
  providerGames: '/provider-games',
  support: "/support",
  favourite: "/favourite",
  sports: "/sports",
  sportsTransactionReport: "/sports/transaction-report",
  tournaments: "/promotions/tournaments",
  cashbackBooster: "/promotions/cashbackBooster",
  casinoWelcome: "/promotions/casinoWelcome"
}

/* ==========================================================================
  Dialog types
========================================================================== */
const DIALOG_TYPE = {
  add: 'add',
  update: 'update',
  delete: 'delete',
  hide: ''
}

/* ==========================================================================
  Table column / cell types
========================================================================== */
const TABLE_CELL_TYPE = {
  status: 'status',
  switch: 'switch',
  button: 'button',
  date: 'date',
  text: 'text',
  disable: 'disable',
  default: 'default',
  updateIconButton: 'updateIconButton',
  deleteIconButton: 'deleteIconButton',
  actionCell: 'actionCell',
  number: 'number',
  array: 'array'
}

/* ==========================================================================
  My account dropdown options
========================================================================== */
const MY_ACCOUNT_DROPDOWN = [
  {
    id: 1,
    label: 'Profile',
    path: ROUTE_PATHS.profile,
    translationLabel: 'profile'
  },
  {
    id: 2,
    label: 'Balance',
    path: ROUTE_PATHS.userbalance,
    translationLabel: 'balance'

  },
  {
    id: 3,
    label: 'Bonus',
    path: ROUTE_PATHS.bonus,
    translationLabel: 'bonus'

  },
  {
    id: 4,
    label: 'Deposit',
    path: ROUTE_PATHS.deposit,
    translationLabel: 'deposit'
  },
  {
    id: 5,
    label: 'Withdraw',
    path: ROUTE_PATHS.withdrawal,
    translationLabel: 'withdraw'

  }
  // {
  //   id: 6,
  //   label: 'Withdraw Request',
  //   path: ROUTE_PATHS.withdrawalRequest,
  // translationLabel:'withdrawRequest'
  // }
]

/* ==========================================================================
  Reports dropdown options
========================================================================== */
const REPORTS_DROPDOWN = [
  {
    id: 1,
    label: 'Transaction Report',
    path: ROUTE_PATHS.transaction,
    translationLabel: 'transactionReport'
  },
  {
    id: 2,
    label: 'Betting Slip Report',
    path: ROUTE_PATHS.betHistory,
    translationLabel: 'bettingHistoryReport'
  },
  {
    id: 2,
    label: 'Sports Transaction Report',
    path: ROUTE_PATHS.sportsTransactionReport,
    translationLabel: 'sportsTransactionReport'
  }
]

/* ==========================================================================
 Deposit Minimum and Maximum  values
========================================================================== */
const DEPOSIT_BLR_VALUES = {
  minimumDeposit: 1,
  maximumDeposit: 100000,
  processingTime: '5 minutes'
}

/* ==========================================================================
 Payment types
========================================================================== */
const PAYMENT_TYPES = {
  PIX: 'pix',
  CREDIT: 'credit'
}

/* ==========================================================================
 Payment response
========================================================================== */
const PAYMENT_RESPONSE_MESSAGE = {
  PROCESSING: 'processing_your_payment',
  CONFIRMED: 'payment_processed_successfully',
  FAILED: 'failure_to_process_payment',
}

/* ==========================================================================
 Withdrawal requests status
========================================================================== */
const WITHDRAW_REQUESTS_STATUS = {
  0: 'PENDING',
  1: 'SUCCESS',
  2: 'CANCELLED',
  3: 'FAILED',
  4: 'ROLLBACK',
  5: 'APPROVED',
  6: 'REJECTED',
  7: 'REQUESTED',
}

/* ==========================================================================
 Withdrawal requests status COLORS
========================================================================== */
const WITHDRAW_REQUESTS_STATUS_COLOR = {
  PENDING: 'warning',
  SUCCESS: 'success',
  CANCELLED: 'error',
  FAILED: 'error',
  ROLLBACK: 'info',
  APPROVED: 'success',
  REJECTED: 'error',
  REQUESTED: 'info',
}

export {
  TOASTER_TYPE,
  LANGUAGES,
  TOKEN,
  ROLE,
  LANGUAGE,
  BETBY_TOKEN,
  IS_FIRST_ACCESS,
  UNIQUE_ID,
  VISIT_SESSION,
  ROUTE_PATHS,
  LOADER_TYPE,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
  DIALOG_TYPE,
  TABLE_CELL_TYPE,
  MY_ACCOUNT_DROPDOWN,
  REPORTS_DROPDOWN,
  DEPOSIT_BLR_VALUES,
  PAYMENT_TYPES,
  PAYMENT_RESPONSE_MESSAGE,
  WITHDRAW_REQUESTS_STATUS,
  WITHDRAW_REQUESTS_STATUS_COLOR,
}
