import PropTypes from "prop-types";
import { CssBaseline, Box } from "@mui/material";
import Header from "../Header";
import Footer from "../Footer";
import LogoutDialog from "components/ui-kit/Dialog/LogoutDialog/index";
import { StyledBox } from "./style";

const MainLayout = ({ children, header, footer }) => {
  return (
    <StyledBox>
      <CssBaseline />
      {header && <Header />}
      <Box className="content">{children}</Box>
      {footer && (
        <Box className="footer">
          <Footer />
        </Box>
      )}
      <LogoutDialog />
    </StyledBox>
  );
};

MainLayout.defaultProps = {
  children: null,
  header: false,
  footer: false,
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
  footer: PropTypes.bool,
};

export default MainLayout;
