import React, { memo, useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DialogComponent } from 'components/ui-kit/Dialog/index'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useNotSigninStyles } from './style'
import { colors } from 'theme/colors/index'

const NotSigninDialog = (props) => {
  const { showDialog, setShowDialogBox } = props;
  const navigate = useNavigate()
  const classes = useNotSigninStyles()
  const [dialogOpen, setDialogOpen] = useState(showDialog)


  const handleDialogClose = () => {
    setDialogOpen(false)
    // navigate(ROUTE_PATHS?.home)
    setShowDialogBox(false)
  }
  const handleDialogLogin = () => {
    setDialogOpen(false)
    navigate(ROUTE_PATHS?.login)
    setShowDialogBox(false)
  }

  const { t } = useTranslation()
  return (
    <DialogComponent dialogOpen={dialogOpen}>
      <Grid container direction='column' paddingY={4} paddingX={3} rowGap={1} alignContent='center'>
        <Grid item xs={6}>
          <Typography variant='h5' color={colors.white} align='center'>
            {t('loginFirst')}
          </Typography>
        </Grid>
        <Grid item container columnGap={2} justifyContent='center' pt={1}>
          <Button variant='contained' onClick={handleDialogClose} className={classes.btnSecondary} style={{ background: colors.bodyBg, color: colors.white }}>{t('cancel')}</Button>
          <Button variant='contained' onClick={handleDialogLogin} className={classes.btnSecondary}>{t('login')}</Button>
        </Grid>
      </Grid>

    </DialogComponent>
  )
}

export default memo(NotSigninDialog)
