import { DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material'

// import { notificationRoot } from 'assets/dom/domNodes'
import CustomButton from 'components/ui-kit/Button'
import { StyledDialog } from './styles'

export const DialogComponent = ({
  dialogOpen,
  handleDialogClose,
  handleAgreeConfirm,
  title,
  subContent,
  children,
  buttonFirstString,
  buttonSecondString,
  fullWidth,
  maxWidth,
  fullScreen,
  className
}) => {
  return (
    <StyledDialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      className={className}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '12px',
          backgroundColor: '#005E82'
        }
      }}
    >
      {!children
        ? (
          <>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {subContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton id='cancel-action' variant='contained' onClick={handleDialogClose}>
                {buttonFirstString}
              </CustomButton>
              <CustomButton variant='contained' id='confirm-action' onClick={handleAgreeConfirm}>
                {buttonSecondString}
              </CustomButton>
            </DialogActions>
          </>
        )
        : (
          children
        )}
    </StyledDialog>
  )
}

// const CustomDialog = ({
//   dialogOpen,
//   handleDialogClose,
//   handleAgreeConfirm,
//   title,
//   subContent,
//   children,
//   buttonFirstString,
//   buttonSecondString,
//   fullWidth,
//   maxWidth,
//   fullScreen
// }) => {
//   return notificationRoot
//     ? (
//         ReactDOM.createPortal(
//           <DialogComponent
//             dialogOpen={dialogOpen}
//             handleDialogClose={handleDialogClose}
//             handleAgreeConfirm={handleAgreeConfirm}
//             title={title}
//             subContent={subContent}
//             buttonFirstString={buttonFirstString}
//             buttonSecondString={buttonSecondString}
//             fullWidth={fullWidth}
//             maxWidth={maxWidth}
//             fullScreen={fullScreen}
//           >
//             {children}
//           </DialogComponent>,
//           notificationRoot
//         )
//       )
//     : (
//       <></>
//       )
// }

// export default CustomDialog

// CustomDialog.defaultProps = {
//   dialogOpen: false,
//   handleDialogClose: () => {},
//   handleAgreeConfirm: () => {},
//   title: 'Default Text',
//   subContent: 'Default Text',
//   buttonFirstString: 'Default Text',
//   buttonSecondString: 'Default Text',
//   fullWidth: true,
//   maxWidth: 'xs',
//   fullScreen: false
// }

// CustomDialog.propTypes = {
//   dialogOpen: PropTypes.bool.isRequired,
//   handleDialogClose: PropTypes.func,
//   handleAgreeConfirm: PropTypes.func,
//   title: PropTypes.string,
//   subContent: PropTypes.string,
//   buttonFirstString: PropTypes.string,
//   buttonSecondString: PropTypes.string,
//   Child: PropTypes.element,
//   fullWidth: PropTypes.bool,
//   maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
//   fullScreen: PropTypes.bool
// }
