

import { styled, Box } from "@mui/material";
export const StyledBox = styled(Box)(
() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  // content: {
  //   flex: "1 0 auto",
  // },
  footer: {
    marginTop: "auto",
  },
})
);