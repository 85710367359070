import config from "config/app.config"
import { ROUTE_PATHS } from "constants/index"

export const headerLinks = (t) => {
  const headerLinkData = [
    {
      show: true,
      name: t('casino'),
      srcUrl: ROUTE_PATHS?.casino
    },
    {
      show: true,
      name: t('liveCasino'),
      srcUrl: ROUTE_PATHS?.liveCasino
    },
    // {
    //   show: config?.BETBY_ENABLED,
    //   name: t('sports'),
    //   srcUrl: ROUTE_PATHS?.sports,
    // },
    {
      show: true,
      name: t('influencers'),
      srcUrl: ROUTE_PATHS?.influencers
    },
    {
      show: true,
      name: t('promotions'),
      srcUrl: ROUTE_PATHS.promotions
    },
    {
      show: true,
      name: t('loyalty'),
      srcUrl: ROUTE_PATHS.loyalty
    },
    {
      show: true,
      name: t('winners'),
      srcUrl: ROUTE_PATHS.winners
    }
  ]
  return headerLinkData

}

export const dateFormat = {
  date_DD_MM_YYYY_Time_H_M_S: "DD-MM-YYYY, HH:MM:SS",
  date_DD_MM_YYYY: "DD-MM-YYYY",
  date_MM_DD_YYYY: "MM-DD-YYYY",
  time_HH_MM_SS: "HH:MM:SS",
  time_stamp: "timestamp"
}
