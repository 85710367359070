import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

const PluginInteractionNavigation = (props) => {
  const navigation = useNavigate()
  function clickEvent() {
      navigation("/influencers")
  }
  const divStyle = {
    display: 'none',
  };
  return (
    <button id="pluginInteractionNavigation" style={divStyle} onClick={ clickEvent } >pluginInteractionNavigation</button>
  )
}

PluginInteractionNavigation.defaultProps ={
  className:"",
  renderLinksData: []
}

export default memo(PluginInteractionNavigation)
