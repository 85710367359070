import { DialogComponent } from "../index";
import { Grid, Typography, Button } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  handelLogoutDialog,
  setLoggedIn,
  setSportsbookToken,
} from "redux-thunk/redux/user/user.slice";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "constants/index";
import { removeAuthToken } from "helpers/cookie.helpers";
import { useTranslation } from "react-i18next";
import { removeBetbyToken, setLocalLanguage } from "helpers/localstorage.helpers";
import { colors } from "theme/colors/index";
import { userLogoutThunk } from "redux-thunk/thunk/user/user.thunk";
import { useEffect } from "react";
const LogoutDialog = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { logoutDialog, isUserLogout } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handelDiaglogClick = ({ doLogout }) => {
    dispatch(handelLogoutDialog({ show: false }));
    dispatch(setLoggedIn({ isLoggedIn: false }));

    if (doLogout) {
      dispatch(userLogoutThunk({}));
    }
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (isUserLogout) {
      removeAuthToken();
      setLocalLanguage("pt");
      removeBetbyToken();
      dispatch(setSportsbookToken(null));
      navigate(ROUTE_PATHS.casino, { replace: true });
      window.location.reload();
    }
  }, [isUserLogout]);

  return (
    <DialogComponent dialogOpen={logoutDialog} className="confirmation-modal">
      <Grid
        container
        direction="column"
        paddingY={4}
        paddingX={3}
        rowGap={1}
        alignContent="center"
      >
        <Grid item xs={6}>
          <Typography variant="h4" color={colors.white} align="center">
            {t("areYouSureYouWantToLogout")}
          </Typography>
        </Grid>
        <Grid item container columnGap={2} justifyContent="center">
          <Button
            variant="contained"
            onClick={() => {
              handelDiaglogClick({ doLogout: false });
            }}
            className={classes.btnSecondary}
            style={{ background: colors.bodyBg, color: colors.white }}
          >
            {t("no")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handelDiaglogClick({ doLogout: true });
            }}
            className={classes.btnSecondary}
          >
            {t("yes")}
          </Button>
        </Grid>
      </Grid>
    </DialogComponent>
  );
};

export default LogoutDialog;
