import { METHOD_TYPES, LOADER_HANDLER_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'


export const fetchTransactionReportsDetailsService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/user/get-user-transactions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
export const fetchBetHistoryDetailsService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/casino/transactions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}

export const fetchSportsTransactionReportService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/sports/transactions', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
export const fetchBonusDetailsService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.get, '/bonus/get-user-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
export const fetchBonusClaimService = (payload) => {
  return axiosInstanceService(METHOD_TYPES.post, '/bonus/claim', payload, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page,
    params: payload
  })
}
