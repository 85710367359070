/**A Helper to format number */
class FormatNumber {
  /**Format number to social media style
   * ```js
   * FormatNumber.toSocialMedia(123400) // 123.4K
   * ```
   */
  static toSocialMedia = (number) => {
    const formattedNumber = Intl.NumberFormat('en', {
      notation: 'compact',
      roundingMode: 'floor'
    }).format(number);
    return formattedNumber;
  }

  static toLongNumber (number) {
    return Intl.NumberFormat('pt').format(number)
  }

  static currency (/** @type { number }*/value, /** @type { 'BRL' | 'USD' }*/ currency = 'BRL') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(value);
  }
}

export default FormatNumber
