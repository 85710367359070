import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackRender from "components/layouts/ErrorPage/index";
import AppToaster from "components/ui-kit/AppToaster/index";
import AppRoutes from "routes/index";
import "./assets/css/App.css";
import { useSelector } from "react-redux";

function App() {
  const pageLoading = useSelector((state) => state.loader.pageLoader);
  return (
    <ErrorBoundary
      fallbackRender={ErrorFallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <AppToaster />
      <AppRoutes pageLoader={pageLoading} />
    </ErrorBoundary>
  );
}

export default App;
